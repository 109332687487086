import { Head } from "@inertiajs/react";
import React from "react";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Head title={"Forgot password"} />
      <div className="flex h-screen flex-col mx-auto w-full absolute inset-0">
        <div className="flex flex-auto flex-col max-w-full md:w-5/12">
          <div className="absolute z-20 flex items-center text-lg font-medium p-8">
            <img
              className="text-white"
              src="/media/octfolio-als-white.svg"
              width="200em"
              alt="Octfolio logo"
            />
          </div>
          <div className="absolute inset-0 hidden md:flex md:w-5/12 bg-[url(/media/auth-bg.webp)] bg-cover" />
          <div className="absolute inset-0 hidden md:flex md:w-5/12 flex-col bg-black opacity-60" />
        </div>
        <div className="flex grow ml-auto w-full md:w-7/12 bg-[var(---surface-default)] shadow-xl h-full">
          <div className="flex flex-col p-8 w-full">
            <div className="flex justify-end space-x-2"></div>
            <div className="grow flex items-center">
              <div className="w-full max-w-md mx-auto">{children}</div>
            </div>
            <div className="flex bottom-0 text-body-text-x-subtle text-xs h-8 items-center">
              <span>
                Photo by{" "}
                <a
                  className="underline"
                  href="https://unsplash.com/@timothy_swope?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
                  target="_blank"
                >
                  Timothy Swope
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
