export const config = {
  INTERCOM_APP_ID: "nxzgj6st",
  MAX_MB_UPLOAD: {
    DEFAULT: 20,
    DOCUMENT: 200,
    IMAGE: 50,
    SIGNATURE: 2,
  },
  UPLOADER: {
    ASSET_IMAGES: 1,
    FORM_IMAGES: 2,
    IMAGES: 3,
    TMP: 4,
    EXPORTS: 5,
  },
};

export const defaultErrorMessage = "Oops! Something went wrong";

export const queryKeys = {
  ALL_SAMPLES: "ALL_SAMPLES",
  USER_CURRENT_WAITLISTS: "USER_CURRENT_WAITLISTS",
  SAMPLE: "SAMPLE",
  SAMPLE_RESULT_OPTIONS: "SAMPLE_RESULT_OPTIONS",
  RECORD_PAGE: "RECORD_PAGE",
  RECORD_PAGE_SIDEBAR: "RECORD_PAGE_SIDEBAR",
};

export const localStorageKeys = {
  LOCK_SCREEN_UNLOCKED: "lock-screen-unlocked",
  LOCK_SCREEN_LOGOUT: "lock-screen-logout",
  SESSION_EXPIRES_AT: "session_expires_at",
  TABLE_SETTING: "table_settings",
};

export const featureFlags = {
  BULK_ACTIONS: "bulk_actions",
};

export type TableName =
  | "adminUsersTable"
  | "assetTable"
  | "auditTable"
  | "documentTable"
  | "recordTable"
  | "sampleTable"
  | "suggestedAuditTable"
  | "asset_assetTable"
  | "asset_auditTable"
  | "asset_documentTable"
  | "asset_recordTable"
  | "asset_sampleTable"
  | "audit_notesTable"
  | "audit_recordTable"
  | "audit_sampleTable"
  | "document_sampleTable"
  | "webregister_recordTable";

export const exportableTableNames: TableName[] = [
  "assetTable",
  "auditTable",
  "documentTable",
  "recordTable",
  "sampleTable",
  "suggestedAuditTable",
];
