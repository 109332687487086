import { confirmable, createConfirmation } from "react-confirm";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDangerAction,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui";

const ConfirmDialog = ({
  show = false,
  proceed = () => {},
  title = "Are you absolutely sure?",
  description,
  confirmButtonText = "Continue",
  cancelButtonText = "Cancel",
  theme = "positive",
}: {
  show?: boolean;
  proceed?: (shouldProceed: boolean) => void;
  title?: string;
  description?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  theme?: "positive" | "destructive";
}) => {
  return (
    <AlertDialog
      open={show}
      onOpenChange={(open) => {
        if (!open) {
          proceed(false);
        }
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => proceed(false)}>
            {cancelButtonText}
          </AlertDialogCancel>
          {theme === "positive" ? (
            <AlertDialogAction onClick={() => proceed(true)}>
              {confirmButtonText}
            </AlertDialogAction>
          ) : (
            <AlertDialogDangerAction onClick={() => proceed(true)}>
              {confirmButtonText}
            </AlertDialogDangerAction>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const ConfirmableDialog = confirmable(ConfirmDialog);

export const confirm = createConfirmation(ConfirmableDialog);
