const OctfolioLogo = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <div className="flex justify-center py-2 opacity-50">
        <svg
          width="62"
          height="10"
          viewBox="0 0 62 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="OCTFOLIO">
            <path
              d="M2.70188 4.91689C2.70188 5.31695 2.76784 5.68935 2.89978 6.03408C3.03171 6.37882 3.22749 6.69376 3.4871 6.97891C3.77225 7.28959 4.0957 7.53006 4.45746 7.70029C4.81922 7.86628 5.19374 7.94927 5.58104 7.94927C6.39393 7.94927 7.07488 7.65986 7.6239 7.08105C8.17718 6.50224 8.45381 5.78085 8.45381 4.91689C8.45381 4.12528 8.17505 3.45071 7.61752 2.89318C7.06424 2.33139 6.39818 2.0505 5.61934 2.0505C4.80645 2.0505 4.11698 2.32927 3.55094 2.8868C2.9849 3.44433 2.70188 4.12103 2.70188 4.91689ZM0.793076 4.98073C0.793076 4.47002 0.861171 3.98909 0.997362 3.53796C1.13355 3.08257 1.33571 2.65698 1.60384 2.26117C2.06348 1.59298 2.64016 1.07801 3.33389 0.716256C4.02761 0.350243 4.78517 0.167236 5.60657 0.167236C6.22369 0.167236 6.81739 0.286403 7.38769 0.524737C7.96225 0.763071 8.47509 1.10568 8.92623 1.55255C9.39013 2.01645 9.74337 2.53781 9.98596 3.11662C10.2286 3.69543 10.3498 4.30403 10.3498 4.94243C10.3498 5.60636 10.2349 6.22986 10.0051 6.81293C9.77955 7.39599 9.44545 7.91309 9.00283 8.36423C8.55596 8.83664 8.0346 9.20052 7.43877 9.45588C6.84719 9.70698 6.22794 9.83253 5.58104 9.83253C4.88731 9.83253 4.24892 9.70911 3.66585 9.46227C3.08279 9.21542 2.56569 8.84941 2.11455 8.36423C1.68044 7.90458 1.35061 7.38961 1.12504 6.81931C0.903731 6.24475 0.793076 5.6319 0.793076 4.98073Z"
              fill="white"
            />
            <path
              d="M20.2258 3.11024H18.0042C17.7403 2.75274 17.4254 2.48461 17.0594 2.30586C16.6976 2.12711 16.2848 2.03773 15.8209 2.03773C15.4464 2.03773 15.091 2.10796 14.7548 2.2484C14.4185 2.38885 14.1206 2.59314 13.861 2.86126C13.5844 3.14216 13.3695 3.46774 13.2162 3.83801C13.0673 4.20402 12.9928 4.58067 12.9928 4.96797C12.9928 5.81916 13.2652 6.53416 13.8099 7.11297C14.359 7.68753 15.0293 7.9748 15.8209 7.9748C16.2763 7.9748 16.6849 7.88969 17.0466 7.71945C17.4126 7.54921 17.7084 7.30236 17.934 6.97891H20.162C19.7279 7.92799 19.1491 8.64086 18.4255 9.11753C17.702 9.5942 16.8381 9.83253 15.8337 9.83253C15.1357 9.83253 14.4909 9.69847 13.8993 9.43034C13.312 9.16222 12.7843 8.76429 12.3161 8.23655C11.9203 7.79818 11.6203 7.30023 11.416 6.7427C11.2117 6.18092 11.1095 5.58082 11.1095 4.94243C11.1095 4.29552 11.233 3.68266 11.4798 3.10385C11.7267 2.52079 12.0884 1.99092 12.5651 1.51425C13.0162 1.07163 13.5163 0.737536 14.0653 0.51197C14.6186 0.282147 15.2123 0.167236 15.8464 0.167236C16.8636 0.167236 17.7318 0.409826 18.4511 0.895006C19.1746 1.38019 19.7662 2.1186 20.2258 3.11024Z"
              fill="white"
            />
            <path
              d="M25.9522 0.352371V1.98666H24.1966V9.6474H22.3836V1.98666H20.628V0.352371H25.9522Z"
              fill="white"
            />
            <path
              d="M27.0214 9.6474V0.352371H31.6115V1.26528H27.9215V4.52109H31.6115V5.44038H27.9215V9.6474H27.0214Z"
              fill="white"
            />
            <path
              d="M33.9702 4.9552C33.9702 6.08728 34.3383 7.03636 35.0746 7.80244C35.8109 8.56851 36.7196 8.95155 37.8006 8.95155C38.856 8.95155 39.7477 8.56426 40.4754 7.78967C41.2032 7.01508 41.5671 6.06175 41.5671 4.92966C41.5671 3.87418 41.1926 2.96553 40.4435 2.20372C39.6945 1.43764 38.7922 1.05461 37.7367 1.05461C37.3069 1.05461 36.8919 1.12908 36.4919 1.27804C36.0961 1.42275 35.73 1.63767 35.3938 1.92282C34.9512 2.29735 34.6022 2.75486 34.3469 3.29537C34.0957 3.83588 33.9702 4.38915 33.9702 4.9552ZM33.0126 4.94243C33.0126 4.29552 33.136 3.67841 33.3829 3.09108C33.634 2.50376 33.9957 1.97815 34.4681 1.51425C34.9235 1.06737 35.4236 0.731152 35.9684 0.505586C36.5174 0.280019 37.1111 0.167236 37.7495 0.167236C38.4007 0.167236 39.0029 0.282147 39.5562 0.51197C40.1137 0.737536 40.6223 1.07588 41.0819 1.52702C41.5543 1.99092 41.9097 2.51015 42.148 3.0847C42.3864 3.65926 42.5055 4.2785 42.5055 4.94243C42.5055 5.6021 42.4034 6.20858 42.1991 6.76185C41.9948 7.31087 41.6884 7.81095 41.2798 8.26208C40.8074 8.78131 40.2733 9.17286 39.6774 9.43673C39.0816 9.7006 38.4347 9.83253 37.7367 9.83253C36.4004 9.83253 35.2789 9.36438 34.3724 8.42807C33.4659 7.4875 33.0126 6.32562 33.0126 4.94243Z"
              fill="white"
            />
            <path
              d="M48.0946 9.6474H43.9067V0.352371H44.826V8.74726H48.0946V9.6474Z"
              fill="white"
            />
            <path
              d="M50.3256 9.6474H49.4127V0.352371H50.3256V9.6474Z"
              fill="white"
            />
            <path
              d="M52.6716 4.9552C52.6716 6.08728 53.0397 7.03636 53.776 7.80244C54.5123 8.56851 55.4209 8.95155 56.502 8.95155C57.5574 8.95155 58.4491 8.56426 59.1768 7.78967C59.9046 7.01508 60.2685 6.06175 60.2685 4.92966C60.2685 3.87418 59.894 2.96553 59.1449 2.20372C58.3959 1.43764 57.4936 1.05461 56.4381 1.05461C56.0083 1.05461 55.5933 1.12908 55.1933 1.27804C54.7975 1.42275 54.4314 1.63767 54.0952 1.92282C53.6526 2.29735 53.3036 2.75486 53.0482 3.29537C52.7971 3.83588 52.6716 4.38915 52.6716 4.9552ZM51.714 4.94243C51.714 4.29552 51.8374 3.67841 52.0843 3.09108C52.3354 2.50376 52.6971 1.97815 53.1695 1.51425C53.6249 1.06737 54.125 0.731152 54.6698 0.505586C55.2188 0.280019 55.8125 0.167236 56.4509 0.167236C57.1021 0.167236 57.7043 0.282147 58.2575 0.51197C58.8151 0.737536 59.3237 1.07588 59.7833 1.52702C60.2557 1.99092 60.6111 2.51015 60.8494 3.0847C61.0878 3.65926 61.2069 4.2785 61.2069 4.94243C61.2069 5.6021 61.1048 6.20858 60.9005 6.76185C60.6962 7.31087 60.3898 7.81095 59.9812 8.26208C59.5088 8.78131 58.9747 9.17286 58.3788 9.43673C57.783 9.7006 57.1361 9.83253 56.4381 9.83253C55.1017 9.83253 53.9803 9.36438 53.0738 8.42807C52.1673 7.4875 51.714 6.32562 51.714 4.94243Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

OctfolioLogo.displayName = "OctfolioLogo";

export { OctfolioLogo };
