import { useState } from "react";

export const useLocalStorage = <T = string>(
  keyName: string,
  defaultValue: T,
): [value: T, setValue: (value: T) => void] => {
  const [data, setData] = useState<T>(() => {
    const value = localStorage.getItem(keyName);

    if (value) {
      try {
        const data = JSON.parse(value) as { data: T };
        if (data.data) {
          return data.data;
        }
      } catch (_e) {
        //
      }
    }

    return defaultValue;
  });

  const setValue = (value: T) => {
    localStorage.setItem(keyName, JSON.stringify({ data: value }));
    setData(value);
  };

  return [data, setValue];
};
