import { useEffect } from "react";
import {
  ControllerRenderProps,
  FieldValues,
  useController,
  useFormContext,
} from "react-hook-form";

import { FormScoreRating, TODO } from "@/types";

import { DynamicFormField } from "./DynamicForm";
import { RiskBadge, RiskBadgeProps } from "./RiskBadge";
import {
  FormDescription,
  FormFieldContext,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui";

// import { FormControl, Input } from "./ui";

export type ScoreInput = {
  input: "SCORE_INPUT";
  scoreDependsOn: number[];
  scoreRatings: FormScoreRating[];
};

export const FormScoreInput = (
  props: Omit<DynamicFormField, "type"> & {
    type: ScoreInput;
  },
) => {
  const { field } = useController({ name: props.name });
  useScore(field, props.type.scoreDependsOn);
  const ratingFilterId = field.name === "24" ? "0" : field.name;
  const scoreRating = props.type.scoreRatings.find(
    (rating) =>
      String(rating.score_id) === field.value &&
      String(rating.form_question_id) === ratingFilterId,
  );
  const label = scoreRating?.rating || "";
  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <FormLabel className="text-body-text-default">{props.label}</FormLabel>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <RiskBadge
          label={label}
          score={Number(field.value)}
          color={scoreRating?.badge_colour as RiskBadgeProps["color"]}
          size={"sm"}
        />
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};
FormScoreInput.displayName = "FormScoreInput";
// FormScoreInput.whyDidYouRender = true;

const getFieldScore = (fieldValue: TODO) => {
  let score = 0;
  if (fieldValue) {
    if (typeof fieldValue === "string" && !isNaN(Number(fieldValue))) {
      score = Number(fieldValue);
    } else if (typeof fieldValue === "object") {
      const fieldObject = fieldValue;
      if ("result_score" in fieldObject) {
        const sample = fieldValue;
        if (!sample.archived && sample.result_score) {
          score += Number(sample.result_score);
        }
      } else {
        const value = fieldValue;
        if (value.score && !isNaN(Number(value.score))) {
          score = Number(value.score);
        }
      }
    }
  }
  return score;
};

const useScore = (
  field: ControllerRenderProps<FieldValues, string>,
  scoreDependsOn: number[],
) => {
  const { value, onChange, name: fieldName } = field;
  const { watch } = useFormContext();
  const hasScore = scoreDependsOn.length;
  let score = 0;
  const fieldsToWatch = scoreDependsOn
    .map((s) => String(s))
    .filter((f) => f !== fieldName);
  const values = watch(fieldsToWatch);
  const hasNegative = values.find((v) => getFieldScore(v) < 0);
  if (hasNegative) {
    score = -1;
  } else {
    for (let i = 0; i < values.length; i += 1) {
      score += getFieldScore(values[i]);
    }
    score = Math.round(score);
  }

  const nextScore = String(score);

  useEffect(() => {
    if (hasScore && value !== nextScore) {
      console.log(
        `Updating score for ${fieldName} from ${value} to ${nextScore}`,
      );
      // onChange(nextScore);
      const handler = setTimeout(() => {
        onChange(nextScore);
      }, 500);
      return () => clearTimeout(handler);
    }
  }, [fieldName, hasScore, onChange, nextScore, value]);
};
