import React from "react";
import RSAsyncSelect from "react-select/async";
import * as z from "zod";

export const getAsynsSelectSchema = (required_error = "") =>
  z.object(
    {
      label: z.string().min(1),
      value: z.string().min(1),
    },
    { required_error },
  );

export const AsyncSelect = React.forwardRef<
  React.ElementRef<typeof RSAsyncSelect>,
  React.ComponentPropsWithoutRef<typeof RSAsyncSelect>
>((props, ref) => {
  const { defaultOptions, ...otherProps } = props;
  return (
    <RSAsyncSelect
      ref={ref}
      {...otherProps}
      defaultOptions={defaultOptions || true}
      classNames={{
        input: () => "react-select-input",
      }}
    />
  );
});

AsyncSelect.displayName = "AsyncSelect";
