import { Icon } from "@/components/Icon";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTheme } from "@/providers/ThemeProvider";

export function ThemeToggle() {
  const { setTheme } = useTheme();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="tertiary" size="icon">
          <ButtonTrigger />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setTheme("light")}>
          Light
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme("dark")}>
          Dark
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme("system")}>
          System
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

const ButtonTrigger = () => {
  const { theme } = useTheme();

  if (theme === "system") {
    const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";

    switch (systemTheme) {
      case "light":
        return <LightModeButton />;
      case "dark":
        return <DarkModeButton />;
    }
  }

  switch (theme) {
    case "light":
      return <LightModeButton />;
    case "dark":
      return <DarkModeButton />;
  }
};

const LightModeButton = () => {
  return <Icon icon={"fa-lg fa-sun-bright"} />;
};

const DarkModeButton = () => {
  return <Icon icon={"fa-lg fa-moon-stars"} />;
};
