import { VariantProps, cva } from "class-variance-authority";

import { Icon } from "./Icon";

const riskBadgeVariants = cva(
  "inline-flex flex-row items-center text-sm font-medium [&>span.risk-score]:flex [&>span.risk-score]:items-center [&>span.risk-score]:justify-center [&>span.risk-label]:flex [&>span.risk-label]:items-center [&>span.risk-label]:justify-center [&>span.risk-score]:border [&>span.risk-score]:bg-body-color-neutral-m99",
  {
    variants: {
      color: {
        black:
          "[&>span.risk-score]:border-body-color-neutral-p99 [&>span.risk-label]:border-body-color-neutral-p99",
        gray: "[&>span.risk-score]:border-body-color-neutral-default [&>span.risk-label]:border-body-color-neutral-default",
        maroon:
          "[&>span.risk-score]:border-red-600 [&>span.risk-label]:border-red-600",
        red: "[&>span.risk-score]:border-red-500 [&>span.risk-label]:border-red-500",
        orange:
          "[&>span.risk-score]:border-[#FF6E00] [&>span.risk-label]:border-[#FF6E00]",
        green:
          "[&>span.risk-score]:border-green-500 [&>span.risk-label]:border-green-500",
        blue: "[&>span.risk-score]:border-blue-600 [&>span.risk-label]:border-blue-600",
      },
      size: {
        sm: "rounded-lg [&>span.risk-score]:h-7 [&>span.risk-score]:w-7 [&>span.risk-score]:rounded-l-lg [&>span.risk-label]:px-1.5 [&>span.risk-label]:h-7 [&>span.risk-label]:rounded-r-lg",
        default:
          "[&>span.risk-score]:h-8 [&>span.risk-score]:w-8 [&>span.risk-label]:px-2 [&>span.risk-label]:h-8",
      },
      mode: {
        outlined:
          "[&>span.risk-label]:bg-body-color-neutral-m99 [&>span.risk-score]:border-r-0 [&>span.risk-label]:border-l-0 [&>span.risk-label]:border",
        contained: "",
      },
    },
    compoundVariants: [
      {
        color: "black",
        mode: "contained",
        className:
          "[&>span.risk-label]:text-body-color-neutral-m99 [&>span.risk-label]:bg-body-color-neutral-p99 [&>span.risk-score]:text-body-color-neutral-p99",
      },
      {
        color: "black",
        mode: "outlined",
        className:
          "[&>span.risk-label]:text-body-color-neutral-p99 [&>span.risk-score]:text-body-color-neutral-p99",
      },
      {
        color: "gray",
        mode: "contained",
        className:
          "[&>span.risk-label]:text-body-color-neutral-m99 [&>span.risk-label]:bg-body-color-neutral-default [&>span.risk-score]:text-body-color-neutral-default",
      },
      {
        color: "gray",
        mode: "outlined",
        className:
          "[&>span.risk-label]:text-body-color-neutral-default [&>span.risk-score]:text-body-color-neutral-default",
      },
      {
        color: "maroon",
        mode: "contained",
        className:
          "[&>span.risk-label]:text-body-color-neutral-m99 [&>span.risk-label]:bg-red-600 [&>span.risk-score]:text-red-600",
      },
      {
        color: "maroon",
        mode: "outlined",
        className:
          "[&>span.risk-label]:text-red-600 [&>span.risk-score]:text-red-600",
      },
      {
        color: "red",
        mode: "contained",
        className:
          "[&>span.risk-label]:text-body-color-neutral-m99 [&>span.risk-label]:bg-red-500 [&>span.risk-score]:text-red-500",
      },
      {
        color: "red",
        mode: "outlined",
        className:
          "[&>span.risk-label]:text-red-500 [&>span.risk-score]:text-red-500",
      },
      {
        color: "orange",
        mode: "contained",
        className:
          "[&>span.risk-label]:text-body-color-neutral-m99 [&>span.risk-label]:bg-[#FF6E00] [&>span.risk-score]:text-[#FF6E00]",
      },
      {
        color: "orange",
        mode: "outlined",
        className:
          "[&>span.risk-label]:text-[#FF6E00] [&>span.risk-score]:text-[#FF6E00]",
      },
      {
        color: "green",
        mode: "contained",
        className:
          "[&>span.risk-label]:text-body-color-neutral-m99 [&>span.risk-label]:bg-green-500 [&>span.risk-score]:text-green-500",
      },
      {
        color: "green",
        mode: "outlined",
        className:
          "[&>span.risk-label]:text-green-500 [&>span.risk-score]:text-green-500",
      },
      {
        color: "blue",
        mode: "contained",
        className:
          "[&>span.risk-label]:text-body-color-neutral-m99 [&>span.risk-label]:bg-blue-600 [&>span.risk-score]:text-blue-600",
      },
      {
        color: "blue",
        mode: "outlined",
        className:
          "[&>span.risk-label]:text-blue-600 [&>span.risk-score]:text-blue-600",
      },
    ],
    defaultVariants: {
      color: "black",
      size: "default",
      mode: "contained",
    },
  },
);

export type RiskBadgeProps = VariantProps<typeof riskBadgeVariants> & {
  label: string;
  score: number | null;
  icon?: string;
};

export const RiskBadge = (props: RiskBadgeProps) => {
  return (
    <div className="w-auto inline-block">
      <div
        className={riskBadgeVariants({
          color: props.color,
          size: props.size,
          mode: props.mode,
        })}
      >
        {props.score !== null && (
          <span className="risk-score aspect-1">
            {props.icon ? (
              <Icon icon={props.icon} iconStyle={"SOLID"} />
            ) : (
              props.score
            )}
          </span>
        )}
        <span className="risk-label whitespace-nowrap uppercase">
          {props.label}
        </span>
      </div>
    </div>
  );
};
