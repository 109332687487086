import { router } from "@inertiajs/react";
import React from "react";
import { useErrorBoundary } from "react-error-boundary";

import { Icon } from "@/components/Icon";
import { Button } from "@/components/ui";

const ErrorComponent = () => {
  const { resetBoundary } = useErrorBoundary();
  const reset = () => {
    resetBoundary();
    window.location.href = route("dashboard");
    router.reload();
  };
  return (
    <section className="w-full h-screen flex flex-col items-center justify-center bg-gray-100 dark:bg-gray-900 p-4">
      <div className="space-y-6 lg:px-64">
        <div className="text-4xl flex items-center gap-4 font-bold text-gray-900 dark:text-gray-100">
          <Icon icon="fa-bug" className="h-10 w-10" />
          <h1>{"That didn't look right"}</h1>
        </div>
        <p className="text-lg text-gray-600 dark:text-gray-300 text-pretty">
          Oops! Something went wrong on our end. Our engineers have been
          notified and are working on a fix. Please try again later or contact
          support for assistance.
        </p>
        <Button onClick={reset}>Return to dashboard</Button>
      </div>
    </section>
  );
};

export { ErrorComponent };
