import React, { createContext, useContext, useEffect, useState } from "react";

import { TBreadcrumb } from "@/types/breadcrumb-types";
import { useLocalStorage } from "@/hooks/useLocalStorage";

type BreadcrumbContextType = {
  breadcrumbState: TBreadcrumb[];
  setBreadcrumbState: React.Dispatch<TBreadcrumb[]>;
  linkMap: Record<string, string>;
  getLinkWithQuery: (key?: string) => string | undefined;
};

const BreadcrumbContext = createContext<BreadcrumbContextType>(null!);

export const BreadcrumbProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [breadcrumbState, setBreadcrumbState] = useState<TBreadcrumb[]>([]);
  const [linkMap, setLinkMap] = useLocalStorage<Record<string, string>>(
    "breadcrumbLinks",
    {},
  );

  const getLinkWithQuery = (link?: string) => {
    if (!link) {
      return;
    }

    // return the link if not found in map
    return linkMap[link] ?? link;
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const parts = currentUrl.split("?");

    const newLinkMap = { ...linkMap };

    if (parts[0] === currentUrl) {
      if (!newLinkMap[parts[0]]) {
        return;
      }

      delete newLinkMap[parts[0]];
    } else {
      newLinkMap[parts[0]] = currentUrl;
    }

    setLinkMap(newLinkMap);
  }, [breadcrumbState]);

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbState,
        setBreadcrumbState,
        linkMap,
        getLinkWithQuery,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumbContext = () => {
  const context = useContext(BreadcrumbContext);
  if (context === undefined) {
    throw new Error(
      "useBreadcrumbContext must be used within a BreadcrumbProvider",
    );
  }
  return context;
};
