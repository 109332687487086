import { Link } from "@inertiajs/react";

import { AuditRow } from "@/types";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui";
import { ALIGN_OPTIONS } from "@radix-ui/react-popper";
import { ReactNode } from "react";

export const AuditAssetDropdownMenu = (props: {
  audit: AuditRow;
  align?: (typeof ALIGN_OPTIONS)[number];
  children?: ReactNode;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={props.audit.assets_count === 0}>
        {props.children ? (
          props.children
        ) : (
          <Button variant="secondary" className="h-8 w-8 p-0 text-sm">
            {props.audit.assets_count}
          </Button>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align={props.align || "start"}
        className="max-h-96 overflow-y-auto"
      >
        {props.audit.assets.map((item) => (
          <Link
            href={route("asset.show", {
              asset: item.asset_route,
              page: "overview",
            })}
            key={item.asset_id}
          >
            <DropdownMenuItem>
              {item.asset_code ? item.asset_code + " - " : ""}
              {item.asset_name}
            </DropdownMenuItem>
          </Link>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
