import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { CSSProperties } from "react";
import { DisplayMenuCheckboxItem } from "./ui";

export const SortableDisplayMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DisplayMenuCheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DisplayMenuCheckboxItem> & {
    rowKey: string;
  }
>((props) => {
  const { children, rowKey, ...rest } = props;
  const {
    transform,
    transition,
    attributes,
    listeners,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: rowKey,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
  };

  return (
    <DisplayMenuCheckboxItem
      {...rest}
      ref={setNodeRef}
      key={rowKey}
      {...attributes}
      {...listeners}
      style={style}
    >
      {children}
    </DisplayMenuCheckboxItem>
  );
});
SortableDisplayMenuCheckboxItem.displayName = "SortableDropdownMenu";
