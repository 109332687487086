import React from "react";

import { cn } from "@/lib/utils";

export interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: string;
  iconStyle?: IconStyle;
}

export type IconStyle = "REGULAR" | "SOLID" | "LIGHT";

const Icon = React.forwardRef<HTMLDivElement, IconProps>(
  ({ icon, iconStyle, className, ...props }, ref) => {
    const getIconStyleClass = () => {
      switch (iconStyle) {
        case "SOLID":
          return "fa-solid";
        case "LIGHT":
          return "fa-light";
        default:
          return "fa-regular";
      }
    };
    return (
      <div
        className={cn(
          "w-5 h-5 inline-flex items-center justify-center shrink-0",
          className,
        )}
        {...props}
        ref={ref}
      >
        <i
          className={`h-4 w-4 inline-flex items-center justify-center ${icon} ${getIconStyleClass()}`}
        />
      </div>
    );
  },
);
Icon.displayName = "Icon";

const RiskBarIcon = ({ steps = 3, activeSteps = 0, color = "#111928" }) => {
  const svgHeight = 20;
  const svgWidth = 20;
  const padding = 0;
  const adjustedWidth = svgWidth - padding * 2;
  const adjustedHeight = svgHeight - padding * 2;
  const barWidth = adjustedWidth / (2 * steps - 1);
  const heightIncrement = adjustedHeight / steps;
  const inactiveColor = "#D1D5DB";

  // Generate the bars based on the number of steps
  const rects = Array.from({ length: steps }, (_, i) => (
    <rect
      key={i}
      x={padding + i * 2 * barWidth} // Add padding to the x position
      y={svgHeight - padding - heightIncrement * (i + 1)} // Start from the bottom with padding
      width={barWidth} // The width of the bar
      height={heightIncrement * (i + 1)} // The height of the bar increments with each step
      rx="1" // Rounded corners
      fill={i < activeSteps ? color : inactiveColor} // Color based on active steps
    />
  ));

  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {rects}
    </svg>
  );
};

export { Icon, RiskBarIcon };
