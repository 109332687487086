import { Link } from "@inertiajs/react";
import { Fragment, ReactNode } from "react";

import { Icon } from "@/components/Icon";
import { useAppContext } from "@/providers/AppProvider";
import { useBreadcrumbContext } from "@/providers/BreadcrumbProvider";
import { TBreadcrumb } from "@/types/breadcrumb-types";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
} from "./ui";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "./ui/breadcrumb";

export const Breadcrumbs = ({
  append,
  children,
}: {
  append?: TBreadcrumb[];
  children?: ReactNode;
}) => {
  const { toggleMobileSidebar, toggleDesktopSidebar, canToggleSidebar } =
    useAppContext();

  const { breadcrumbState, getLinkWithQuery } = useBreadcrumbContext();

  const breadcrumbs = [...breadcrumbState, ...(append || [])];

  const [firstCrumb, ...rest] = breadcrumbs;
  const numberOfTrailingBreadCrumbs = Math.min(rest.length, 3);
  const trailingCrumbs = rest.slice(-numberOfTrailingBreadCrumbs);
  const extraCrumbs = rest.slice(0, rest.length - numberOfTrailingBreadCrumbs);

  console.info({
    breadcrumbs,
    firstCrumb,
    trailingCrumbs,
    extraCrumbs,
    numberOfTrailingBreadCrumbs,
  });

  return (
    <nav
      className={`flex w-full px-2 items-center h-[44px] z-30 shrink-0 text-body-text-default hover:shadow-sm`}
      aria-label="Breadcrumb"
    >
      {canToggleSidebar && (
        <div className="hidden lg:flex pr-2">
          <IconButton
            onClick={() => toggleDesktopSidebar()}
            variant="tertiary"
            icon="fa-bars"
          />
        </div>
      )}
      <div className="lg:hidden">
        <IconButton
          onClick={() => toggleMobileSidebar()}
          variant="tertiary"
          icon="fa-bars"
        />
      </div>
      <Breadcrumb>
        <BreadcrumbList>
          {firstCrumb && (
            <BreadcrumbItem>
              <BreadcrumbLink href={getLinkWithQuery(firstCrumb.link)}>
                {firstCrumb.icon && <Icon icon={firstCrumb.icon} />}
                {firstCrumb.label}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          {extraCrumbs.length > 0 && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      size={"icon"}
                      variant={"tertiary"}
                      className="focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:rounded-md"
                    >
                      <Icon icon="fa-ellipsis" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {extraCrumbs.map((breadcrumb) => {
                      return (
                        <DropdownMenuItem asChild key={breadcrumb.label}>
                          {!breadcrumb.link ? (
                            breadcrumb.label
                          ) : (
                            <Link
                              href={getLinkWithQuery(breadcrumb.link) || ""}
                            >
                              {breadcrumb.label}
                            </Link>
                          )}
                        </DropdownMenuItem>
                      );
                    })}
                  </DropdownMenuContent>
                </DropdownMenu>
              </BreadcrumbItem>
            </>
          )}
          {trailingCrumbs.map((breadcrumb, index) => {
            return (
              <Fragment key={index}>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbLink href={getLinkWithQuery(breadcrumb.link)}>
                    {breadcrumb.icon && <Icon icon={breadcrumb.icon} />}
                    {breadcrumb.label}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Fragment>
            );
          })}
        </BreadcrumbList>
      </Breadcrumb>
      <div className="flex flex-1 justify-end">{children}</div>
    </nav>
  );
};
