import { localStorageKeys } from "@/lib/constants";
import {
  Column,
  ColumnOrderState,
  VisibilityState,
} from "@tanstack/react-table";

export const filterColumnCallback = <T = unknown>(column: Column<T>) =>
  !column.id.startsWith("action") &&
  column.id !== "actions" &&
  column.id !== "drag-handle" &&
  column.id !== "select";

export const getSettingsFromStorage = ({
  tableName,
  settingsName,
}: {
  tableName: string;
  settingsName: "columnVisibility" | "columnOrder";
}) => {
  const allTableSettings = localStorage.getItem(localStorageKeys.TABLE_SETTING);
  if (allTableSettings) {
    const parsedTableSettings = JSON.parse(allTableSettings);
    const tableSettings = parsedTableSettings[tableName];
    if (tableSettings && tableSettings[settingsName]) {
      return tableSettings[settingsName];
    }
  }
};

export const setSettingsInStorage = ({
  tableName,
  settings,
}: {
  tableName: string;
  settings:
    | {
        name: "columnVisibility";
        settings: VisibilityState;
      }
    | {
        name: "columnOrder";
        settings: ColumnOrderState;
      };
}) => {
  let allTableSettings: Record<
    string,
    { columnVisibility: VisibilityState; columnOrder: ColumnOrderState }
  > = {};
  const allTableSettingsString = localStorage.getItem(
    localStorageKeys.TABLE_SETTING,
  );
  if (allTableSettingsString) {
    allTableSettings = JSON.parse(allTableSettingsString);
  }
  allTableSettings[tableName] = {
    ...allTableSettings[tableName],
    [settings.name]: settings.settings,
  };
  localStorage.setItem(
    localStorageKeys.TABLE_SETTING,
    JSON.stringify(allTableSettings),
  );
};
