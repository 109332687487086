import { Link } from "@inertiajs/react";
import { cva } from "class-variance-authority";
import * as React from "react";

import { ButtonProps, IconButton } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { TODO } from "@/types";

import { Icon } from "../Icon";

const paginationVariants = cva(
  "inline-flex items-center border justify-center gap-2 rounded text-sm whitespace-nowrap disabled:cursor-not-allowed disabled:opacity-50 hover:shadow-sm focus:outline-none focus-visible:ring focus-visible:ring-offset-2 focus-visible:rounded",
  {
    variants: {
      variant: {
        primary: cn(
          "bg-pagination-button-default-bg text-pagination-button-default-text border-pagination-button-default-border",
          "hover:bg-pagination-button-hover-bg hover:text-pagination-button-hover-text hover:border-pagination-button-hover-border",
          "data-[active=true]:bg-pagination-button-active-bg data-[active=true]:text-pagination-button-active-text data-[active=true]:border-pagination-button-active-border",
          "disabled:bg-pagination-button-disabled-bg disabled:text-pagination-button-disabled-text disabled:border-pagination-button-disabled-border",
        ),
      },
      size: {
        icon: "h-8 w-8",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "icon",
    },
  },
);

const Pagination = ({ className, ...props }: React.ComponentProps<"nav">) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn("mx-auto flex w-full justify-center", className)}
    {...props}
  />
);

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<"ul">
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn("flex flex-row items-center gap-1", className)}
    {...props}
  />
));
PaginationContent.displayName = "PaginationContent";

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<"li">
>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn("", className)} {...props} />
));
PaginationItem.displayName = "PaginationItem";

type PaginationLinkProps = {
  isActive?: boolean;
} & Pick<ButtonProps, "size"> &
  React.ComponentProps<TODO>; // Should be typeof Link however that fails

const PaginationLink = ({
  className,
  isActive,
  size = "icon",
  ...props
}: PaginationLinkProps) => (
  <PaginationItem>
    <Link
      as="button"
      aria-current={isActive ? "page" : undefined}
      className={cn(
        paginationVariants({
          variant: "primary",
          size,
        }),
        isActive &&
          "bg-pagination-button-active-bg text-pagination-button-active-text border-pagination-button-active-border",
        className,
      )}
      {...props}
    />
  </PaginationItem>
);
PaginationLink.displayName = "PaginationLink";

const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    alt="Previous page"
    aria-label="Go to previous page"
    className={cn(className)}
    {...props}
  >
    <Icon icon="fa-arrow-left" iconStyle="SOLID" />
  </PaginationLink>
);
PaginationPrevious.displayName = "PaginationPrevious";

const PaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    alt="Next page"
    aria-label="Go to next page"
    className={className}
    {...props}
  >
    <Icon icon="fa-arrow-right" iconStyle="SOLID" />
  </PaginationLink>
);

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<"span">) => (
  <span
    aria-hidden
    className={cn("flex h-8 w-8 items-center justify-center", className)}
    {...props}
  >
    <IconButton icon="fa-ellipsis" iconStyle="SOLID" variant="tertiary" />
    <span className="sr-only">More pages</span>
  </span>
);

export {
  Pagination,
  PaginationContent,
  PaginationLink,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
  PaginationEllipsis,
};
