import { MultiUploadInput } from "@/components/FormMultiUploadInput";
import { DynamicFormField } from "@/components/DynamicForm";
import { useController } from "react-hook-form";
import {
  FormControl,
  FormDescription,
  FormFieldContext,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui";

export type SingleUpload = {
  input: "SINGLE_UPLOAD";
  uploader: number;
};

type FormSingleUploadInputProps = Omit<DynamicFormField, "type"> & {
  type: Omit<SingleUpload, "input">;
};

export const FormSingleUploadInput = (props: FormSingleUploadInputProps) => {
  const { field } = useController({ name: props.name });
  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <FormLabel className="text-body-text-default">{props.label}</FormLabel>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <FormControl>
          <MultiUploadInput
            files={field.value ? [field.value] : []}
            onChange={(files) => field.onChange(files?.[0] || "")}
            uploader={props.type.uploader}
            maxFiles={1}
          />
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};
