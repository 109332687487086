import { Fragment, ReactNode } from "react";

import { useSampleResultOptions } from "@/hooks/sample-api";

import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui";

export type SampleResultMultiSelectProps = {
  value: number[];
  onChange: (value: number[]) => void;
  trigger?: ReactNode;
};

export const SampleResultMultiSelect = (
  props: SampleResultMultiSelectProps,
) => {
  const { data: sampleResultOptions } = useSampleResultOptions();
  const { value, onChange, trigger } = props;

  if (!sampleResultOptions) {
    return null;
  }

  const selectedGroups = sampleResultOptions
    .filter((group) => {
      return value.find((v) => group.options.find((o) => o.value === v));
    })
    .filter((group) => group.group !== 0);

  return (
    <>
      <div className="inline-flex flex-wrap -mt-2">
        {value.map((v) => {
          const group = sampleResultOptions.find((group) =>
            group.options.find((o) => o.value === v),
          );
          const option = group?.options.find((o) => o.value === v);
          if (!option) {
            return null;
          }
          return (
            <Badge className="mr-2 mt-2" key={v}>
              {option?.label}
            </Badge>
          );
        })}
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          {trigger || (
            <Button className="mt-2" variant="secondary">
              Add Result
            </Button>
          )}
        </DropdownMenuTrigger>

        <DropdownMenuContent>
          {sampleResultOptions.map((group, groupIndex) => {
            const isGroupEnabled =
              group.group === 0 ||
              value.length === 0 ||
              selectedGroups.length === 0 ||
              selectedGroups.includes(group);
            return (
              <Fragment key={group.group}>
                {group.options.map((option) => {
                  const optionIndexInValue = value.findIndex(
                    (v) => option.value === v,
                  );
                  const isSelected = optionIndexInValue >= 0;
                  return (
                    <DropdownMenuCheckboxItem
                      className="w-full"
                      key={option.value}
                      onSelect={(e) => {
                        e.preventDefault();
                      }}
                      checked={value.includes(option.value)}
                      onCheckedChange={() => {
                        if (!isSelected) {
                          onChange([...value, option.value]);
                        } else {
                          onChange([
                            ...value.slice(0, optionIndexInValue),
                            ...value.slice(optionIndexInValue + 1),
                          ]);
                        }
                      }}
                      disabled={!isGroupEnabled}
                    >
                      {option.label}
                    </DropdownMenuCheckboxItem>
                  );
                })}
                {groupIndex < sampleResultOptions.length - 1 && (
                  <DropdownMenuSeparator />
                )}
              </Fragment>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
