import { router } from "@inertiajs/react";
import { ImageIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useController } from "react-hook-form";

import { config } from "@/lib/constants";
import { TODO } from "@/types";

import { DynamicFormField } from "./DynamicForm";
import {
  Button,
  FormControl,
  FormDescription,
  FormFieldContext,
  FormItem,
  FormLabel,
  FormMessage,
  IconButton,
} from "./ui";
import { Progress } from "./ui/progress";
import { PhotoActions } from "./PhotoActions";

export type MultiUpload = {
  input: "MULTI_UPLOAD";
  uploader: number;
  maxFiles?: number;
};

type FormMultiUploadInputProps = Omit<DynamicFormField, "type"> & {
  type: Omit<MultiUpload, "input">;
  asDropdown?: boolean;
};

export const FormMultiUploadInput = (props: FormMultiUploadInputProps) => {
  const { field } = useController({ name: props.name });
  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <FormLabel className="text-body-text-default">{props.label}</FormLabel>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <FormControl>
          <MultiUploadInput
            files={field.value}
            onChange={field.onChange}
            uploader={props.type.uploader}
            maxFiles={props.type.maxFiles}
            asDropdown={props.asDropdown}
          />
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};

export type RemoteFile = {
  photo_id: number;
  url: URL;
  primary_photo?: boolean;
};

export type LocalFile = File & {
  preview: string;
  url?: URL;
  status: "IDLE" | "UPLOADING" | "UPLOADED" | "ERROR";
};

export type CameraValue = {
  url: string;
  photo_id: number;
  status?: "UPLOADING" | "UPLOADED" | "ERROR";
};

type MultiUploadInputProps = {
  files: (RemoteFile | LocalFile | string)[];
  onChange: (files: (RemoteFile | LocalFile | string)[]) => void;
  uploader: number;
  maxFiles?: number;
  asDropdown?: boolean;
};

export const MultiUploadInput = ({
  files,
  onChange,
  uploader,
  maxFiles = Infinity,
  asDropdown = true,
}: MultiUploadInputProps) => {
  const maxSizeInMB = config.MAX_MB_UPLOAD.DEFAULT;
  const maxSize = maxSizeInMB * 1024 * 1024;

  const onDrop = <T extends File>(acceptedFiles: T[]) => {
    const newFiles = acceptedFiles?.map((acceptedFile) => {
      return Object.assign(acceptedFile, {
        preview: URL.createObjectURL(acceptedFile),
        status: "UPLOADING" as LocalFile["status"],
      });
    });
    onChange([...files, ...newFiles]);
  };

  const removeFile = (index: number) => {
    const newFiles = files.filter((file, i) => i !== index);
    onChange(newFiles);
  };

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: { "image/*": [] },
    maxSize,
    disabled: files.length >= maxFiles || false,
  });

  const isFileTooLarge = fileRejections?.[0]?.file.size > maxSize;

  const onUploadComplete = useCallback(
    (url: URL, index: number) => {
      const newFiles = files.map((file, i) => {
        if (i === index) {
          file = file as LocalFile;
          return {
            ...file,
            url,
            status: "UPLOADED" as LocalFile["status"],
          };
        }
        return file;
      });
      onChange(newFiles);
    },
    [files, onChange],
  );

  return (
    <div
      {...getRootProps({
        className: "w-full border-2 border-dashed rounded-lg",
      })}
    >
      <input {...getInputProps()} />
      <div className="h-full grid grid-cols-3 p-1 gap-2">
        {files?.map((file, index) => {
          if (typeof file === "string") {
            return (
              <div key={file} className="w-full aspect-1 bg-gray-900 relative">
                <img
                  className="h-full w-full object-contain"
                  src={file}
                  alt={""}
                />
                <IconButton
                  type="button"
                  variant="danger"
                  className="absolute top-1 right-1"
                  onClick={() => removeFile(index)}
                  icon="fa-trash"
                />
              </div>
            );
          }

          if ("preview" in file) {
            return (
              <div key={index} className="relative">
                <UploadPreview
                  localFile={file}
                  uploader={uploader}
                  onUploadComplete={(data: UploadResponse) => {
                    onUploadComplete(data.url, index);
                  }}
                />
              </div>
            );
          } else {
            return (
              <div
                key={file.photo_id}
                className="w-full aspect-1 bg-gray-900 relative group"
              >
                <img
                  className="h-full w-full object-contain"
                  src={String(file.url)}
                  alt={""}
                />
                <PhotoActions
                  asDropdown={asDropdown}
                  file={file}
                  onSetAsPrimary={(photoId) => {
                    const updatedPhotos = files.map((file) => {
                      if (typeof file !== "string" && "photo_id" in file) {
                        return {
                          ...file,
                          primary_photo: file.photo_id === photoId,
                        };
                      }
                      return file;
                    });
                    onChange(updatedPhotos);
                  }}
                  onDelete={() => {
                    removeFile(index);
                  }}
                />
              </div>
            );
          }
        })}
        {files.length < maxFiles && (
          <div className={files.length === 0 ? "col-start-2" : "col-auto"}>
            <div className="h-full flex flex-col items-center justify-center">
              <ImageIcon className="h-8 w-8" />
              <div className={"text-sm text-muted-foreground"}>
                Max {maxSizeInMB}MB
              </div>
              <Button
                type="button"
                className="mt-4"
                variant="secondary"
                onClick={open}
              >
                Select
              </Button>
              {isFileTooLarge && (
                <div className="text-[0.8rem] font-medium text-destructive mt-2">
                  File is too large.
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

type UploadResponse = {
  url: URL;
};

const UploadPreview = ({
  localFile,
  uploader,
  onUploadComplete,
}: {
  localFile: LocalFile;
  uploader: number;
  onUploadComplete: (data: UploadResponse) => void;
}) => {
  const { uploadFile, uploadProgress } = useMediaUpload();
  useEffect(() => {
    uploadFile({
      fileType: "image",
      file: localFile,
      uploadURL: route("api.uploads"),
      uploader,
      onUploadComplete,
    });
  }, [localFile, uploadFile, uploader, onUploadComplete]);

  useEffect(() => {
    const beforeUnloadHandler = (event: TODO) => {
      // Recommended
      event.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;
    };
    if (localFile.status === "UPLOADING") {
      window.addEventListener("beforeunload", beforeUnloadHandler);
      const removeStartEventListener = router.on("before", (event) => {
        if (!confirm("Upload in progress. Are you sure you want to leave?")) {
          return event.preventDefault();
        }
        // TODO: implement cancel upload logic here
      });
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
        removeStartEventListener();
      };
    }
  }, [localFile.status]);
  return (
    <div className="w-full aspect-1 bg-gray-900">
      <img
        className="h-full w-full object-contain"
        src={localFile.preview}
        alt={localFile.name}
      />
      {localFile.status === "UPLOADING" && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Progress value={uploadProgress} className="w-[60%]" />
        </div>
      )}
    </div>
  );
};

export const useMediaUpload = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadRef = useRef<XMLHttpRequest | null>(null);

  useEffect(() => {
    return () => {
      if (uploadRef.current) {
        uploadRef.current.abort();
        uploadRef.current = null;
      }
    };
  }, []);

  const startUpload = useCallback(
    async (
      uploadURL: string,
      formData: TODO,
      onUploadComplete: (response: TODO) => void,
      onUploadError?: (error: TODO) => void,
    ) => {
      if (uploadRef.current) {
        return;
      }
      uploadRef.current = new XMLHttpRequest();
      uploadRef.current.responseType = "json";
      uploadRef.current.open("POST", uploadURL);
      uploadRef.current.upload.addEventListener(
        "progress",
        ({ loaded, total }) => {
          setUploadProgress((loaded * 100) / total);
        },
      );
      uploadRef.current.send(formData);
      uploadRef.current.onload = async () => {
        const status = uploadRef.current?.status;
        const response = uploadRef.current?.response;

        if (!String(status).startsWith("2") || !response) {
          onUploadError?.(
            new Error(`Failed to upload photo, ${uploadRef.current?.status}`),
          );

          return;
        }

        onUploadComplete(response);
        uploadRef.current = null;
      };
      uploadRef.current.onerror = async () => {
        const error = uploadRef.current?.response;
        if (onUploadError) {
          onUploadError(error);
        }
      };
    },
    [],
  );

  const uploadFile = useCallback(
    async ({
      fileType,
      uploadURL,
      uploader,
      file,
      onUploadComplete,
      onUploadError,
    }: {
      fileType: "image" | "video";
      uploadURL: string;
      uploader: number;
      file: File;
      onUploadComplete: (data: TODO) => void;
      onUploadError?: () => void;
    }) => {
      const formData = new FormData();
      if (fileType === "image") {
        formData.append("file", file);
        formData.append("upload_id", uploader.toString());
      } else {
        formData.append("file", file);
        formData.append("upload_id", uploader.toString());
      }
      startUpload(uploadURL, formData, onUploadComplete, onUploadError);
    },
    [startUpload],
  );

  return {
    uploadFile,
    uploadProgress,
  };
};
