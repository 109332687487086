//#region
import { router } from "@inertiajs/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ColumnDef,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  Row,
  SortingState,
  Updater,
  useReactTable,
} from "@tanstack/react-table";
import { axios } from "@/axios";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { Icon } from "@/components/Icon";
import { DataTablePagination } from "@/components/Pagination";
import {
  Input,
  RowDragHandleCell,
  SortableTableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRow,
} from "@/components/ui";
import { usePageData } from "@/hooks/usePageData";
import { generateColumnDefs } from "@/lib/generate-column-defs";
import { useTableServiceContext } from "@/providers/TableServiceProvider";
import { PaginatedResource, TODO } from "@/types";

import { LoadingSpinner } from "./LoadingSpinner";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { toast } from "sonner";
import { DatatableExport } from "@/components/DatatableExport";
import { exportableTableNames, TableName } from "@/lib/constants";
import { cn } from "@/lib/utils";
import {
  DataTableRowSelectionProps,
  TableSelectionToolbar,
  useRowSelection,
  useRowSelectionColumn,
} from "./data-table/row-selection";
import { DisplayOptions } from "./data-table/display-options";
import {
  filterColumnCallback,
  getSettingsFromStorage,
} from "./data-table/utils";
import { DataTableFilters } from "./data-table/filters";

//#endregion

const FALLBACK_PAGE_LIMIT = 50;

type DataTableProps<TData, TValue> = {
  tableName: TableName;
  routeName: string;
  routeParams?: Record<string, string>;
  extraFilters?: Record<string, unknown>[];
  actionColumns?: ColumnDef<TData, TValue>[];
  prefixColumns?: ColumnDef<TData, TValue>[];
  extraActions?: ReactNode;
  syncWithURL?: boolean;
  refetchInterval?: number;
  onRowClick?: (row: Row<TData>) => void;
  getRowId?: (originalRow: TData, index: number, parent?: Row<TData>) => string;
  export?: (typeof exportableTableNames)[number];
  exportParams?: Record<string, string>;
  dndSort?: {
    sortKey: keyof TData;
    onChange: (data: TData[]) => Promise<void>;
  };
  selection?: DataTableRowSelectionProps<TData>;
  selectedRow?: string;
};

export const DataTable = <TData extends object, TValue>(
  props: DataTableProps<TData, TValue>,
) => {
  const {
    platform: { translations },
  } = usePageData();
  const queryClient = useQueryClient();
  const { setPreviousTableRouteObject, setPreviousTableData } =
    useTableServiceContext();
  const [params, setParams] = useState(() => {
    if (!props.syncWithURL) {
      return {
        search: "",
        order_by: "",
        order_direction: "",
        limit: FALLBACK_PAGE_LIMIT,
        filters: [],
        page: 1,
        displayFlags: {} as Record<string, "true" | "false" | "">,
      };
    }
    const urlParams = route().params;
    console.log({ urlParams });
    const {
      search,
      order_by,
      order_direction,
      limit,
      filters,
      page,
      ...displayFlags
    } = urlParams;
    return {
      search: String(search || ""),
      order_by: String(order_by || ""),
      order_direction: String(order_direction || ""),
      limit: Number(limit || FALLBACK_PAGE_LIMIT),
      filters: filters
        ? (JSON.parse(atob(decodeURIComponent(filters as string))) as [])
        : [],
      page: Number(page || 1),
      displayFlags: displayFlags as Record<string, "true" | "false" | "">,
    };
  });

  const queryKey = [
    props.routeName,
    params,
    props.routeParams,
    props.extraFilters,
  ];

  const { data: tableData, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const { displayFlags, ...rest } = params;

      const routeName = props.routeName;
      const routeParams = {
        ...rest,
        ...props.routeParams,
        filters: btoa(
          JSON.stringify([...params.filters, ...(props.extraFilters || [])]),
        ),
        ...displayFlags,
      };

      const routeUrl = route(routeName, routeParams);

      setPreviousTableRouteObject({
        routeName,
        routeParams,
      });

      const response = await axios.get<PaginatedResource<TData>>(routeUrl);

      try {
        const indexedData = response.data.data.map((item: TODO) => {
          return {
            totalCount: response.data.meta.total,
            objectId: item.id as number,
            routeKey: item.route_key as string,
            currentPage: response.data.meta.current_page,
            perPage: response.data.meta.per_page,
          };
        });

        setPreviousTableData(indexedData);
      } catch (error) {
        console.error(error);
      }

      return response.data;
    },
    placeholderData: (prev) => prev,
    refetchInterval: props.refetchInterval,
  });

  const dndSortColumns = useMemo(() => {
    if (
      !props.dndSort ||
      (params.order_by && params.order_by !== "") ||
      (params.order_direction && params.order_direction !== "")
    ) {
      return [];
    }

    return [
      {
        id: "drag-handle",
        header: "",
        cell: ({ row }: { row: Row<TData> }) => (
          <RowDragHandleCell rowId={row.id} />
        ),
        size: 60,
      },
    ];
  }, [props.dndSort, params.order_by, params.order_direction]);

  const reloadPage = (newParams: typeof params) => {
    const { displayFlags, ...rest } = newParams;
    router.get(
      route(route().current() as string, {
        ...route().params,
        ...rest,
        limit: String(newParams.limit),
        page: String(newParams.page),
        filters: btoa(JSON.stringify([...newParams.filters])),
        ...displayFlags,
      }),
      {},
      {
        preserveState: true,
        replace: true,
      },
    );
  };

  const sorting: SortingState = [
    { id: params.order_by, desc: params.order_direction !== "asc" },
  ];

  const setSorting = (sortUpdater: Updater<SortingState>) => {
    const newSorting = functionalUpdate(sortUpdater, sorting);
    if (!newSorting[0]) {
      return;
    }
    const new_order_by = newSorting[0].id;
    const new_order_direction = newSorting[0].desc ? "desc" : "asc";
    if (
      new_order_by === params.order_by &&
      new_order_direction === params.order_direction
    ) {
      return;
    }
    const newParams = {
      ...params,
      order_by: new_order_by,
      order_direction: new_order_direction,
    };
    setParams(newParams);
    if (props.syncWithURL) {
      reloadPage(newParams);
    }
  };

  const { selectionColumn } = useRowSelectionColumn(props.selection);

  const data = useMemo(() => {
    return tableData ? tableData.data : ([] as TData[]);
  }, [tableData]);
  const columns = useMemo(() => {
    return tableData
      ? [
          ...selectionColumn,
          ...dndSortColumns,
          ...(props.prefixColumns || []),
          ...generateColumnDefs<TData>(tableData.columns),
          ...(props.actionColumns || []),
        ]
      : [];
  }, [
    tableData,
    selectionColumn,
    dndSortColumns,
    props.prefixColumns,
    props.actionColumns,
  ]);

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: props.selection?.enabled,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    onSortingChange: setSorting,
    state: {
      sorting,
      columnVisibility: getSettingsFromStorage({
        tableName: props.tableName,
        settingsName: "columnVisibility",
      }),
      columnOrder: getSettingsFromStorage({
        tableName: props.tableName,
        settingsName: "columnOrder",
      }),
    },
    enableSortingRemoval: false,
    getRowId: props.getRowId,
  });

  const updateParam = (key: string, value: unknown) => {
    const newParams = {
      ...params,
      [key]: value,
    };
    setParams(newParams);
    if (props.syncWithURL) {
      reloadPage(newParams);
    }
  };

  const onSearch = (query: string) => updateParam("search", query);
  const debouncedHandleSearch = useDebouncedCallback(onSearch, 500);

  const tableColumnsForDisplayDropdown = table
    .getAllLeafColumns()
    .filter(filterColumnCallback);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const tableRows = table.getRowModel().rows;

  const [rows, setRows] = useState<Row<TData>[] | null>(tableRows);

  const dataIds = rows?.map((item) => item.id);

  const { mutate } = useMutation({
    mutationFn: async (data: TData[]) => props.dndSort?.onChange(data),
    onMutate: async (newData: TData[]) => {
      // Cancel any outgoing refetch
      // (so they don't overwrite our optimistic update)

      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const prevData = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(
        queryKey,
        (oldData: PaginatedResource<TData>) => ({
          ...oldData,
          data: newData,
        }),
      );

      // Return a context object with the snapshot value
      return { prevData };
    },
    onError: (_err, _newTodo, context) => {
      toast.error("Unable to update sequence. Your changes have been reverted");
      queryClient.setQueryData(queryKey, context?.prevData);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const onDragEnd = async (e: DragEndEvent) => {
    if (!props.dndSort) {
      return;
    }

    const { active, over } = e;
    if (active && over && active.id !== over.id && rows && dataIds) {
      const activeIndex = dataIds.indexOf(active.id as string);
      const overIndex = dataIds.indexOf(over.id as string);

      // If active or over id is not found, return early
      if (activeIndex === -1 || overIndex === -1) {
        return;
      }

      const updatedRows = arrayMove(rows, activeIndex, overIndex);

      setRows([...updatedRows]);

      // Find the range of indices affected by the move
      const startIndex = Math.min(activeIndex, overIndex);
      const endIndex = Math.max(activeIndex, overIndex);

      const fallbackValue = (params.page - 1) * params.limit;

      let startIndexSequence = Number(
        updatedRows[startIndex - 1]?.original[props.dndSort.sortKey] ||
          fallbackValue,
      );

      // Recalculate sequence numbers only for rows within this range
      for (let i = startIndex; i <= endIndex; i++) {
        // Ensure to increment the sequence relative to its order in the table
        updatedRows[i].original[props.dndSort.sortKey] = String(
          startIndexSequence + 1,
        ) as TData[keyof TData];
        startIndexSequence++;
      }

      // Prepare the data to be passed to the onChange callback
      const updatedData = updatedRows.map((row) => row.original);

      mutate(updatedData);
    }
  };

  useEffect(() => {
    if (tableRows) {
      setRows(tableRows);
    }
  }, [tableRows]);

  const { isSelectionMode } = useRowSelection(props.selection, table);

  if (isLoading || !tableData) {
    return <LoadingSpinner />;
  }

  const exportColumns = tableColumnsForDisplayDropdown
    .filter(
      (column) =>
        column.getIsVisible() &&
        !props.actionColumns?.find(
          (actionColumn) =>
            actionColumn.header === (column.columnDef.header as string),
        ),
    )
    .map((column) => column.columnDef.header as string);

  const hiddenSortableColumns = tableData.columns
    .filter((column) => column.hidden && column.exportable)
    .map((column) => column.header);

  return (
    <div className="flex-1 overflow-hidden">
      <div className="h-full flex flex-col">
        <div className="p-4">
          <div className="flex flex-row justify-between">
            <div>
              <DataTableFilters
                allFilters={tableData.filters}
                filters={params.filters}
                onFilterChange={(filters) => updateParam("filters", filters)}
              />
            </div>
            <div className="flex flex-shrink-0 space-x-2">
              <TableSelectionToolbar
                table={table}
                selection={props.selection}
              />
              {props.extraActions}
              {props.export && (
                <DatatableExport
                  export={props.export}
                  exportParams={props.exportParams}
                  columns={[...exportColumns, ...hiddenSortableColumns]}
                />
              )}
              <div className="inline-flex">
                <DisplayOptions
                  params={params}
                  updateParam={updateParam}
                  tableData={tableData}
                  table={table}
                  tableName={props.tableName}
                />
              </div>
              <div className="inline-flex">
                <Input
                  className="h-8"
                  type="search"
                  placeholder={translations.search}
                  defaultValue={params.search}
                  onChange={(e) => {
                    debouncedHandleSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col rounded-md overflow-hidden">
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
            sensors={sensors}
          >
            <Table className="border-separate border-spacing-x-0">
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableHeadRow key={headerGroup.id} className="group/row">
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                          style={{
                            width:
                              header.id === "select"
                                ? header.getSize()
                                : undefined,
                            minWidth:
                              header.id === "select"
                                ? header.getSize()
                                : undefined,
                            maxWidth:
                              header.id === "select"
                                ? header.getSize()
                                : undefined,
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <span className="flex flex-row items-center">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {{
                                asc: (
                                  <Icon
                                    icon="fa-angle-up"
                                    className="ml-1 text-xs"
                                  />
                                ),
                                desc: (
                                  <Icon
                                    icon="fa-angle-down"
                                    className="ml-1 text-xs"
                                  />
                                ),
                              }[header.column.getIsSorted() as string] ?? null}
                              {!header.column.getIsSorted() &&
                                header.column.getCanSort() && (
                                  <Icon
                                    icon="fa-angles-up-down"
                                    className="ml-1 text-xs"
                                  />
                                )}
                            </span>
                          )}
                        </TableHead>
                      );
                    })}
                  </TableHeadRow>
                ))}
              </TableHeader>
              <TableBody>
                {rows?.length ? (
                  <SortableContext
                    items={dataIds as UniqueIdentifier[]}
                    strategy={verticalListSortingStrategy}
                  >
                    {rows.map((row) => (
                      <SortableTableRow
                        key={row.id}
                        rowKey={row.id}
                        data-state={row.getIsSelected() && "selected"}
                        className={cn(
                          props.onRowClick
                            ? "group/row cursor-pointer"
                            : "group/row",
                          row.getIsSelected() &&
                            "bg-blue-100 hover:bg-blue-150 transition-colors duration-100",
                          props.getRowId &&
                            props.selectedRow &&
                            props.selectedRow === row.id
                            ? "bg-blue-150 transition-colors duration-100"
                            : "",
                        )}
                        onClick={(e) => {
                          if (isSelectionMode) {
                            const enabled = props.selection?.isRowEnabled(
                              row.original,
                            );
                            if (enabled) {
                              row.toggleSelected();
                            }
                            return;
                          }
                          if (
                            props.onRowClick &&
                            e.nativeEvent.target instanceof HTMLTableCellElement
                          ) {
                            props.onRowClick(row);
                          }
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            className="border-y-[0.5px] border-table-body-default-border"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </SortableTableRow>
                    ))}
                  </SortableContext>
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </DndContext>
        </div>
        {
          <DataTablePagination
            {...tableData.meta}
            onLinkClick={(link) => {
              updateParam(
                "page",
                Object.fromEntries(new URL(link.url).searchParams).page,
              );
            }}
          />
        }
      </div>
    </div>
  );
};
