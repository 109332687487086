import { VariantProps, cva } from "class-variance-authority";

import { AuditStatusValue } from "@/types";

const badgeVariants = cva(
  "inline-flex items-center rounded-full select-none font-normal text-nowrap",
  {
    variants: {
      status: {
        Planned: "",
        Scheduled: "",
        "In progress": "",
        "Awaiting results": "",
        "Pending review": "",
        Completed: "",
      },
      size: {
        sm: "text-xs [&>span.status]:pl-1 [&>span.status]:pr-1.5 [&>span.status]:py-0.5",
        default:
          "text-sm [&>span.status]:pl-1.5 [&>span.status]:pr-2 [&>span.status]:py-0.5",
      },
      mode: {
        contained: "",
        outlined: "",
      },
    },
    compoundVariants: [
      {
        status: "Planned",
        mode: "contained",
        className:
          "bg-body-surface-default text-body-text-default stroke-body-text-default border border-body-border-default",
      },
      {
        status: "Planned",
        mode: "outlined",
        className:
          "bg-body-surface-default text-body-text-default stroke-body-text-default border border-body-border-default",
      },
      {
        status: "Scheduled",
        mode: "contained",
        className:
          "bg-body-surface-default text-body-text-default stroke-body-text-default border border-body-border-default",
      },
      {
        status: "Scheduled",
        mode: "outlined",
        className:
          "bg-body-surface-default text-body-text-default stroke-body-text-default border border-body-border-default",
      },
      {
        status: "In progress",
        mode: "contained",
        className: "bg-orange-500 text-white",
      },
      {
        status: "In progress",
        mode: "outlined",
        className:
          "bg-body-surface-default text-orange-500 border border-orange-500",
      },
      {
        status: "Awaiting results",
        mode: "contained",
        className: "bg-blue-600 text-white",
      },
      {
        status: "Awaiting results",
        mode: "outlined",
        className:
          "bg-body-surface-default text-blue-600 border border-blue-600",
      },
      {
        status: "Pending review",
        mode: "contained",
        className: "bg-green-700 text-white",
      },
      {
        status: "Pending review",
        mode: "outlined",
        className:
          "bg-body-surface-default text-green-700 border border-green-700",
      },
      {
        status: "Completed",
        mode: "contained",
        className: "bg-gray-950 text-white",
      },
      {
        status: "Completed",
        mode: "outlined",
        className:
          "bg-body-surface-default text-gray-950 border border-gray-950 [&>svg]:fill-red-500",
      },
    ],
    defaultVariants: {
      status: "Scheduled",
      mode: "contained",
      size: "sm",
    },
  },
);

const Icon = ({ status }: { status: AuditStatusValue }) => {
  switch (status) {
    case "Planned":
    case "Scheduled":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8"
            cy="8"
            r="7"
            stroke="currentColor"
            strokeWidth="2"
            strokeDasharray="2 2"
          />
        </svg>
      );
    case "In progress":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" />
          <path
            d="M12 8C12 9.01445 11.6224 9.94069 11 10.6458L8 8V4C10.2091 4 12 5.79086 12 8Z"
            fill="currentColor"
          />
        </svg>
      );
    case "Awaiting results":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" />
          <path
            d="M12 8C12 10.2091 10.2091 12 8 12V4C10.2091 4 12 5.79086 12 8Z"
            fill="currentColor"
          />
        </svg>
      );
    case "Pending review":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" />
          <path
            d="M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8H8V4C10.2091 4 12 5.79086 12 8Z"
            fill="currentColor"
          />
        </svg>
      );
    case "Completed":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="8" fill="currentColor" />
          <path
            d="M11.3438 5.65625C11.5469 5.84375 11.5469 6.17188 11.3438 6.35938L7.34375 10.3594C7.15625 10.5625 6.82812 10.5625 6.64062 10.3594L4.64062 8.35938C4.4375 8.17188 4.4375 7.84375 4.64062 7.65625C4.82812 7.45312 5.15625 7.45312 5.34375 7.65625L6.98438 9.29688L10.6406 5.65625C10.8281 5.45312 11.1562 5.45312 11.3438 5.65625Z"
            className="fill-current invert"
          />
        </svg>
      );
  }
};

export type BadgeProps = VariantProps<typeof badgeVariants> & {
  status: AuditStatusValue;
};

export const AuditStatusBadge = (props: BadgeProps) => {
  return (
    <div
      className={badgeVariants({
        status: props.status,
        mode: props.mode,
        size: props.size,
      })}
    >
      <span className="status inline-flex items-center gap-x-1">
        <Icon status={props.status || "Scheduled"} />
        {props.status}
      </span>
    </div>
  );
};

AuditStatusBadge.displayName = "AuditStatusBadge";
