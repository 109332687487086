import { Document } from "./document-types";

export type SampleRow = {
  route_key: string;
  id: number;
  recordid: string;
  inspectionid: number;
  specificid: string;
  auditid: number;
  version: string;
  risk_score: number;
  fsr24_rating: string;
  fsr24_badge_colour: string;
  approveddate: string | null;
  as_sampleids: string;
  as_barcodes: string;
  as_statuses: string;
  as_results: string;
  as_display_statuses: string;
  as_display_results: string;
};

export type Sample = {
  route_key: string;
  sample_id: number;
  inspection_id: number;
  asset_id: string;
  parent_id: Sample["sample_id"] | null;
  sample_date: string;
  barcode: string;
  status_id: keyof typeof SampleStatus;
  notes: string | null;
  type: string;
  identified: SampleIdentification;
  result_id: keyof typeof SampleResult;
  result_ids?: (keyof typeof SampleResult)[] | null;
  result_score: number;
  offline_sample_id: string;
  lab_cert_number?: null;
  archived: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by?: null;
  certificate_of_analyses?: Document[];
};

export type SampleResultGroup = {
  group: number;
  options: {
    label: string;
    value: number;
    status_id: number;
  }[];
};

export type SampleIdentification = "Sampled" | "Visual assessment";

export const SampleStatus = {
  0: "Presumed",
  1: "Presumed",
  2: "Confirmed",
  3: "Not Present (Tested)",
  4: "Not Present (Not Tested)",
} as const;

export const SampleResult = {
  1: "Awaiting sample analysis",
  2: "Amosite",
  3: "Chrysotile",
  4: "Crocidolite",
  5: "No asbestos detected",
  6: "Unknown mineral fibres",
  7: "Presumed asbestos",
  8: "Organic fibres",
  9: "Synthetic Mineral Fibres",
  10: "Presumed no asbestos",
  11: "No suspect material",
  12: "Strongly presumed asbestos",
} as const;
