import { cn } from "@/lib/utils";

const LoadingSpinner = (props: { text?: string; size?: string }) => {
  return (
    <div className="flex flex-1 h-full justify-center items-center">
      <div className="text-center flex flex-col items-center justify-center">
        <div
          className={cn(
            "animate-spin rounded-full size-24 border-b-2 border-gray-900",
            props.size,
          )}
        ></div>
        {props.text && <p className="text-lg mt-4">{props.text}</p>}
      </div>
    </div>
  );
};

export { LoadingSpinner };
