import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { Icon, IconProps } from "@/components/Icon";

const AlertVariants = cva(
  "flex p-2 gap-x-1 items-center rounded max-w-full text-sm whitespace-nowrap",
  {
    variants: {
      variant: {
        tip: "bg-alert-tip-bg text-alert-tip-text",
        info: "bg-alert-info-bg text-alert-info-text",
        warning: "bg-orange-200 text-orange-700",
        success: "bg-alert-success-bg text-alert-success-text",
        danger: "bg-alert-danger-bg text-alert-danger-text",
      },
    },
    defaultVariants: {
      variant: "tip",
    },
  },
);

const variantIcons: Record<string, IconProps["icon"]> = {
  tip: "fa-lightbulb",
  info: "fa-info-circle",
  warning: "fa-exclamation-triangle",
  success: "fa-check-circle",
  danger: "fa-exclamation-circle",
};

export interface AlertProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof AlertVariants> {
  title: string;
  description: string;
  action?: () => void;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      title,
      description,
      variant = "tip",
      className,
      action: action,
      ...props
    },
    ref,
  ) => {
    const icon = variant ? variantIcons[variant] : variantIcons["tip"];

    const [visible, setVisible] = React.useState(true);

    const handleIconClick = () => {
      if (action) {
        action();
      }
    };

    const handleCloseClick = () => {
      setVisible(false);
    };

    if (!visible) {
      return null;
    }

    return (
      <div
        {...props}
        ref={ref}
        className={`${AlertVariants({ variant })} ${className || ""}`}
      >
        <Icon icon={icon} />
        <div className="flex min-w-0 items-center gap-x-1">
          <span className="font-semibold truncate">{title}</span>
          <span className="flex-shrink-0 truncate">{description}</span>
        </div>
        <div
          className="flex items-center cursor-pointer flex-shrink-0 ml-auto"
          onClick={action ? handleIconClick : handleCloseClick}
        >
          <Icon icon={action ? "fa-arrow-up-right" : "fa-times"} />
        </div>
      </div>
    );
  },
);

Alert.displayName = "Alert";

export { Alert };
