import { featureFlags } from "@/lib/constants";
import { cn } from "@/lib/utils";
import {
  ColumnDef,
  Row,
  RowSelectionState,
  Table,
} from "@tanstack/react-table";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import {
  Checkbox,
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../ui";

export type DataTableRowSelectionProps<TData> =
  | {
      enabled: boolean;
      isRowEnabled: (row: TData) => boolean;
      tooltip?: string | ((row: TData) => string);
      prefill?: string[];
      bulkActions: (
        rowSelectionState: RowSelectionState,
        onDone: () => void,
      ) => ReactNode;
    }
  | undefined;

export const useRowSelectionColumn = <TData,>(
  props: DataTableRowSelectionProps<TData> = {
    enabled: false,
    isRowEnabled: () => true,
    tooltip: undefined,
    prefill: undefined,
    bulkActions: () => null,
  },
) => {
  const isBulkActionsEnabled = useFeatureFlagEnabled(featureFlags.BULK_ACTIONS);
  const selectionColumn: ColumnDef<TData>[] = useMemo(() => {
    if (!props.enabled || !isBulkActionsEnabled) {
      return [];
    }
    return [
      {
        id: "select",
        size: 34,
        minSize: 34,
        maxSize: 34,
        enableResizing: false,
        header: ({ table }: { table: Table<TData> }) => (
          <div
            className={cn(
              "pr-2",
              table.getIsAllPageRowsSelected() ||
                table.getIsSomePageRowsSelected()
                ? ""
                : "opacity-50 hover:opacity-100 transition-opacity duration-100",
            )}
          >
            <Checkbox
              checked={
                table.getIsAllPageRowsSelected()
                  ? true
                  : table.getIsSomePageRowsSelected()
                    ? "indeterminate"
                    : false
              }
              onCheckedChange={() => {
                if (table.getIsSomePageRowsSelected()) {
                  table.toggleAllPageRowsSelected(false);
                  return;
                }
                table.getRowModel().rows.forEach((row) => {
                  if (props.isRowEnabled(row.original)) {
                    row.toggleSelected();
                  }
                });
              }}
              aria-label="Select all"
              className={cn(
                "flex items-center justify-center",
                "relative after:absolute after:inset-[-100%] after:content-[''] after:size-[300%]",
              )}
            />
          </div>
        ),
        cell: ({ row }: { row: Row<TData> }) => {
          const tooltip =
            typeof props.tooltip === "function"
              ? props.tooltip(row.original)
              : props.tooltip;
          const checked = row.getIsSelected();
          const enabled = props.isRowEnabled(row.original);
          return (
            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  className={cn(
                    "group-hover/row:visible",
                    checked ? "visible" : "invisible",
                  )}
                >
                  <div
                    className={cn(
                      checked || !enabled
                        ? ""
                        : "opacity-50 hover:opacity-100 transition-opacity duration-100",
                    )}
                  >
                    <Checkbox
                      checked={checked}
                      disabled={!enabled}
                      onCheckedChange={(checked) => {
                        row.toggleSelected(!!checked);
                      }}
                      aria-label="Select row"
                      className={cn(
                        "flex items-center justify-center",
                        "relative after:absolute after:inset-[-100%] after:content-[''] after:size-[300%]",
                      )}
                    />
                  </div>
                </div>
              </TooltipTrigger>
              {tooltip && (
                <TooltipContent side="right">{tooltip}</TooltipContent>
              )}
            </Tooltip>
          );
        },
      },
    ];
  }, [isBulkActionsEnabled, props]);

  return {
    selectionColumn,
  };
};

export const useRowSelection = <TData,>(
  selection: DataTableRowSelectionProps<TData>,
  table: Table<TData>,
) => {
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const selectionTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const hasSelection = Object.keys(table.getState().rowSelection).length > 0;
  useEffect(() => {
    const toggleSelectionMode = () => {
      if (hasSelection) {
        if (selectionTimeoutRef.current) {
          clearTimeout(selectionTimeoutRef.current);
        }
        setIsSelectionMode(true);
      } else {
        selectionTimeoutRef.current = setTimeout(() => {
          setIsSelectionMode(false);
        }, 1000);
        return () => clearTimeout(selectionTimeoutRef.current);
      }
    };
    toggleSelectionMode();
  }, [hasSelection]);

  useEffect(() => {
    if (
      selection?.enabled &&
      selection?.prefill &&
      selection.prefill.length > 0 &&
      Object.keys(table.getState().rowSelection).length === 0
    ) {
      table.setRowSelection(
        selection.prefill.reduce((p, c) => ({ ...p, [c]: true }), {}),
      );
    }
  }, [table, selection?.enabled, selection?.prefill]);

  return {
    isSelectionMode,
  };
};

type TableSelectionToolbarProps<TData> = {
  table: Table<TData>;
  selection: DataTableRowSelectionProps<TData>;
};

export const TableSelectionToolbar = <TData,>(
  props: TableSelectionToolbarProps<TData>,
) => {
  const isBulkActionsEnabled = useFeatureFlagEnabled(featureFlags.BULK_ACTIONS);

  useEffect(() => {
    if (props.selection?.enabled && isBulkActionsEnabled) {
      const onKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          props.table.setRowSelection({});
        }
      };
      window.addEventListener("keydown", onKeyPress);
      return () => {
        window.removeEventListener("keydown", onKeyPress);
      };
    }
  }, [props.selection?.enabled, props.table, isBulkActionsEnabled]);

  if (!isBulkActionsEnabled) {
    return null;
  }

  const selectedCount = Object.keys(props.table.getState().rowSelection).length;

  return (
    <div
      className={
        cn()
        // "absolute top-2 left-0 right-0 flex justify-center",
        // The below lines enable animation from the top
        // (props.selection?.prefill && props.selection.prefill.length > 0) ||
        //   selectedCount > 0
        //   ? "top-2 animate-in slide-in-from-top-40 duration-500 ease-in-out"
        //   : "-top-40 animate-out slide-out-to-top-40 duration-500 ease-in-out",
      }
    >
      <div className="flex items-center space-x-2">
        {/* <div className="flex items-center space-x-2 rounded-lg border bg-background p-2 shadow-md shadow-black/20 transition-shadow duration-300"> */}
        {selectedCount > 0 && (
          <div className="h-8 pl-2 flex flex-row items-center space-x-1 border border-dashed border-foreground/30 rounded-lg divide-x">
            <span className="text-sm">{selectedCount} selected</span>
            <span>
              <IconButton
                variant="tertiary"
                size="icon"
                icon="fa-xmark"
                onClick={() => {
                  props.table.setRowSelection({});
                }}
                className="h-7 w-7"
              />
            </span>
          </div>
        )}
        {props.selection?.bulkActions(
          props.table.getState().rowSelection,
          () => {
            props.table.setRowSelection({});
          },
        )}
      </div>
    </div>
  );
};
