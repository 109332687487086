import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  Separator,
} from "@/components/ui";
import { AlertType } from "@/types";

import { Alert } from "./Alert";
import { Icon } from "./Icon";

export const QuestionsChangedAlert = (props: { alert: AlertType }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Alert
        title={props.alert.title}
        description={props.alert.description}
        variant={"warning"}
        action={() => {
          setOpen(true);
        }}
      />
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="p-8 flex flex-col gap-y-4 max-w-md">
          <p className="tex-lg font-semibold">
            This checklist has been updated since the previous record{" "}
          </p>
          <p className="text-sm">
            The response provided in the previous version of this record &quot;
            <b>{props.alert.otherInfo?.parentReportAnswer}</b>&quot;, no longer
            exists in the updated checklist. As a result, the response cannot be
            brought across.
          </p>
          <div className="flex items-center justify-center gap-x-2 p-2 bg-body-surface-danger-default rounded">
            <span className="text-xs p-2 border-2 bg-white border-body-border-danger text-body-text-danger-default text-center">
              Previous Response
            </span>
            <Icon icon="fa-arrow-right" />
            <span className="text-xs p-2 border-2 bg-white border-body-border-m3 text-body-text-default w-[116px] text-center">
              ?
            </span>
          </div>
          <p className="text-sm">
            For <b>mandatory</b> questions: A new response must be given.
          </p>
          <p className="text-sm">
            For <b>non-mandatory</b> questions: Providing a response is
            optional. If one is not given, the previous response will be cleared
            from this version.
          </p>

          <div className="flex flex-col p-2 bg-[#F9FAFB] text-body-text-x-subtle text-xs gap-y-2 rounded">
            <span>Record {props.alert.otherInfo?.parentRecordVersion}</span>
            <Separator />
            <div>
              <span className="font-semibold">
                {props.alert.otherInfo?.parentReportQuestion}
                <br />
              </span>
              {props.alert.otherInfo?.parentReportAnswer}
            </div>
          </div>
          <DialogFooter>
            <Button variant={"secondary"} onClick={() => setOpen(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
